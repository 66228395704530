<template>
  <div></div>
</template>

<script>
import { defineComponent } from "vue";
import { useReCaptcha } from "vue-recaptcha-v3";
import { useStore } from "vuex";
import axios from "axios";

export default defineComponent({
  name: "RedirectHome",
  setup() {
    const { executeRecaptcha, recaptchaLoaded } = useReCaptcha();
    const store = useStore();

    const recaptcha = async () => {
      // Wait until recaptcha has been loaded.
      await recaptchaLoaded();

      // Execute reCAPTCHA with action "login".
      const token = await executeRecaptcha("login");

      try {
        if (token != "") {
          store.commit("setRequestData", { field: "token", value: token });
        } else {
          window.location.href = "/";
        }
      } catch (error) {
        // silent fail
      }
    };
    return {
      recaptcha,
    };
  },
  async created() {
    // Guard that checks if all voting steps have been done prior to form submission.
    const stepStatus = this.$store.getters.getAllVotingStepStatuses;
    for (let i = 0; i < stepStatus.length; i++) {
      if (!stepStatus[i].done) {
        window.location.href = "/";
      }
    }

    await this.recaptcha();
    let postData = this.$store.getters.getRequestData;
    postData.godagarning = postData.godagarning.toLowerCase();
    postData.forebild = postData.forebild.toLowerCase();
    postData.storahjarta = postData.storahjarta.toLowerCase();
    postData.platform = postData.platform.toLowerCase();

    await axios
      // .post("https://awards.heyou.se/api/submitvote", postData, {
      .post("https://prod-1.heyou.link/dev/api/v1/votes/submit", postData, {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      })
      .then(async (response) => {
        if (response.status == 200) {
          // console.log("Vote submitted successfully");
          this.$store.commit("clearRequestData");
          this.$store.dispath("clearVotingStepStatus");
          location.href = "/success";
        }
      })
      .catch(() => {
        // Silently fail
        location.href = "/success";
      });
  },
});
</script>

<style lang="scss" scoped></style>
